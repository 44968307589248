/*
*   Fonts
*
 */
$fontSizeBase: 16px;
$lineHeightBase: (22/16);
$fontDefault: 'Helvetica';

/*
*   Colors
*
 */
$colorBlack: black;
$colorWhite: #fff;
$colorBlue: blue;
$colorRed: red;

$colorBackground: $colorWhite;

$colorShark: #282D34;
$colorWedgewood: #4D78A1;
$colorZircon: #FAFCFF;
$colorAthensGray: #E7EBEE;
$colorCasper: #A6BED0;
$colorLaurel: #6D9576;
$colorAlabaster: #FAFAFA;
$colorSilver: #CBCBCB;
$colorSelago: #F8FBFE;
$colorGray: #888;
$colorLynch: #62758A;
$colorWildSand: #f5f5f5;
$colorAlto: #E0E0E0;
$colorSilverRust: #CCC2B8;
$colorText: $colorShark;
/*
*   Breakpoints
*
 */

// The different breakpoints are px values, but the unit has to be omitted
$small: 440;
$smallPlus: 600;
$medium: 750;
$mediumPlus: 980;
$large: 1200;
$largePlus: 1420;
$xlarge: 1800;

$breakpoints: (
        s: $small,
        sp: $smallPlus,
        m: $medium,
        mp: $mediumPlus,
        l: $large,
        lp: $largePlus,
        xl: $xlarge,
);

/*
*   Gutters, margins, max width
*
 */

$gutterMap: (
        min: 4px,
        s: 4px,
        sp: 4px,
        m: 7px,
        mp: 7px,
        l: 21px,
        lp: 21px,
        xl: 21px
);

$outerMarginsMap: (
        min: 40px,
        s: 40px,
        sp: 40px,
        m: 45px,
        mp: 45px,
        l: 60px,
        lp: 120px,
        xl: 120px
);

$maxContentWidth: 1440px;

// If different max widths are needed, comment this in and comment the variable above out.
//
// $maxContentWidth: (
//     min: 1280px,
//     s: 1280px,
//     sp: 1280px,
//     m: 1280px,
//     mp: 1280px,
//     l: 1280px,
//     lp: 1440px,
//     xl: 1600px
// );

// Which unit should the breakpoints use? em, px or rem (em is default)
$breakpointsUnit: px; // PX, EM or REM Media Queries? -> https://zellwk.com/blog/media-query-units/

// Grid plugin settings
$grid-breakpointMap: $breakpoints;
$grid-breakpoints: (s, sp, m, mp, l, lp, xl);
$grid-columns: 12;
$grid-max-width: $maxContentWidth; //Should include support for multiple max-widths at different breakpoings. See NAF Digital
$grid-outer-margins: $outerMarginsMap;
$grid-gutters: $gutterMap;
$grid-flexbox: true;
// $grid-flexbox-test-class: 'flexbox';
$grid-pull-columns: 6;
$grid-push-columns: 6;
$grid-use-long-classnames: false;
$grid-use-em: false;
//$grid-max-width-map: $maxContentWidth; // Optional. Use if different max widths are needed at different breakpoints
