/**
* --- Base setup and resets --------------------------------------------------------------------------------------------
*/

*, *::before, *::after {
    box-sizing: inherit;
}

html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    height: 100%;
    box-sizing: border-box;
}

html, body {
    background-color: $colorBackground;
    color: $colorText;
}

body {
    position: relative;
    overflow-x: hidden;

    font-weight: normal;
    font-size: $fontSizeBase;
    line-height: $lineHeightBase;

    font-family: "Untitled", "Helvetica Neue", Helvetica, Arial, sans-serif;

    //-webkit-font-smoothing: antialiased;
    //-moz-osx-font-smoothing: grayscale;

    // Uncomment if you are using fontface observer.
    // @include font($fontDefault);
    &.has-hero-overlay {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 286px;
            background: linear-gradient(180deg, rgba(0,0,0,0.500437675070028) 0%, rgba(0,0,0,0) 100%);
            z-index: 100;
            pointer-events: none;
        }
    }
}


h1, h2, h3, h4, h5, h6 {
    font-size: 1em;
    display: block;
    margin: 0;
    font-weight: 400;
}

p, small, li {
    display: block;
    margin: 0;
}

ul, ol {
    list-style: none;
    list-style-image: none;
    margin: 0;
    padding: 0;
}

img {
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    height: auto;
}

figure {
    margin: 0;
}

a, input, button {
    -ms-touch-action: none !important;
}

input {
    border-radius: 0;
}

input[type="radio"] {
    -webkit-appearance: radio;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox;
}

textarea {
    resize: none;
}

select {
    border-radius: 0;
}

/* input:-webkit-autofill { -webkit-box-shadow: 0 0 0 1000px $colorInputBackground inset; } */
input::-ms-clear {
    display: none;
}

input[type="search"], input[type="text"], textarea {
    -webkit-appearance: none;
    border-radius: 0;
    box-sizing: border-box;
}

fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

picture {
    display: block;
}

address {
    font-style: normal;
}

a {
    color: inherit;
    text-decoration: none;
}

b, strong {
    font-weight: normal;
}

i, em {
    font-style: normal;
}

button:not(:focus) {
    outline: 0;
}

.no-outline {
    button, a, input, label, textarea, select, option {
        outline: none !important;
    }
}

.lazyload,
.lazyloading {
    opacity: 0;
    transform: translateZ(0);
}

.lazyloaded {
    opacity: 1;
    transition: opacity 0.3s $easeOutQuad;
}

.no-js {
    img.lazyload {
        display: none;
    }
}

[data-reveal] {
    opacity: 0;

    .no-js & {
        opacity: 1;
    }
}

.js-reveal--zoom img {
    transform: scale(1.01, 1.01);
}

