.numbers {
    &__item {
        &:nth-last-of-type(1), &:nth-last-of-type(2):nth-of-type(odd) {
            margin-bottom: 0;
        }

        @include from(l) {
            &:nth-last-of-type(2):nth-of-type(4n - 2), &:nth-last-of-type(3):nth-of-type(4n - 2), &:nth-last-of-type(3):nth-of-type(4n - 3), &:nth-last-of-type(4):nth-of-type(4n - 3) {
                margin-bottom: 0;
            }
        }

        .front & {
            @include from(m) {
                &:nth-last-of-type(2):nth-of-type(4n - 2), &:nth-last-of-type(3):nth-of-type(4n - 2), &:nth-last-of-type(3):nth-of-type(4n - 3), &:nth-last-of-type(4):nth-of-type(4n - 3) {
                    margin-bottom: 0;
                }
            }
        }
    }
}