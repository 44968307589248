.investor {
    &__items {
        @include between(m,l) {
            padding-left: outerMargin(m) - gutter(l)/2;
            padding-right: outerMargin(m) - gutter(l)/2;
        }
    }
    &__item {
        $item: &;
        @include between(m,l) {
            padding-left: gutter(l)/2;
            padding-right: gutter(l)/2;
        }

        &-box {
            #{$item}:nth-of-type(3n-2) & {
                top: 0;
                left: 0;
            }

            #{$item}:nth-of-type(3n-1) & {
                bottom: 0;
                left: 0;
            }

            #{$item}:nth-of-type(3n) & {
                bottom: 0;
                right: 0;
            }
        }
    }
}