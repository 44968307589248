.page {

    &__block {
        padding-top: 60px;

        @include from(m) {
            padding-top: 80px;
        }

        @include from(l) {
            padding-top: 100px;
        }

        @include from(lp) {
            padding-top: 130px;
        }

        &:last-of-type {
            padding-bottom: 60px;

            @include from(m) {
                padding-bottom: 80px;
            }

            @include from(l) {
                padding-bottom: 100px;
            }

            @include from(lp) {
                padding-bottom: 130px;
            }

            &.factboxes, &.linkboxes {
                padding-bottom: 0;
            }
        }

        .hero + & {
            @include from(m) {
                align-items: flex-start;
            }
        }
    }

    &__quote {
        // &:before {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     left: -31px;
        //     width: 8px;
        //     height: 100%;
        //     background: $colorAthensGray;

        //     @include from(l) {
        //         left: -40px;
        //     }
        // }
    }

    &__image-box {
        width: 100vw; 
        left: -(outerMargin());

        @include from(m) {
            left: -(outerMargin(m));
        }
        @include from(mp) {
            min-height: 35vw;
            left: 0;
            width: 100%;
        }
    }

    &__image-box-inner {
        .page--case & {
            background: $colorWedgewood;
        }

        .page--generic & {
            background: $colorLaurel;
        }

        width: 100%;

        @include until(mp) {
            padding-bottom: 66%;
        }

        @include from(mp) {
            width: calc(((100vw - #{outerMargin(mp) * 2} + #{gutter(mp)}) * (5/12)) + #{outerMargin(mp)});
            max-width: none;
            max-height: 895px;
            flex: 0 0 auto;
        }

        @include from(l) {
            width: calc(((100vw - #{outerMargin(l) * 2} + #{gutter(l)}) * (5/12)) + #{outerMargin(l)});
        }

        @include from($maxContentWidth + (2 * outerMargin(xl))) {
            width: calc(50vw - ( #{$maxContentWidth + gutter(xl)} / 12 ) - #{gutter(xl)/2});
        }
    }

    &__image-outer {
        @include until(m) {
            padding-bottom: 90% !important;
        }
    }

    &__image {
        position: relative;
        height: 0;
        padding-bottom: 90%;
        

        @include from(m) {
            width: 100%;
            padding-bottom: 0;
            height: 100%;
            max-height: 700px;
            flex: 0 0 auto;
        }

        @include from(l) {
            max-height: 800px;
            width: calc(100% + #{outerMargin(l) + gutter(l)})
        }

        @include from(lp) {
            max-height: 1200px;
            width: calc(100% + #{outerMargin(lp) + gutter(lp)})
        }

        @include from($maxContentWidth + (2 * outerMargin(xl))) {
            width: calc(50vw - ( #{$maxContentWidth + gutter(xl)} * (1/6) ));
        }

        &--right {
            right: -(outerMargin());
            @include from(m) {
                right: -(outerMargin(m));
                min-height: 36vw;
            }

            @include from(l) {
                right: -(outerMargin(l));
            }
    
            @include from(lp) {
                right: -(outerMargin(lp));
            }
    
            @include from($maxContentWidth + (2 * outerMargin(xl))) {
                right: calc((100vw - #{$maxContentWidth}) / -2);
            }
        }

        &--left {
            left: -(outerMargin());
            @include from(m) {
                left: -(outerMargin(m));
                min-height: 36vw;
            }
    
            @include from(l) {
                left: -(outerMargin(l));
            }
    
            @include from(lp) {
                left: -(outerMargin(lp));
            }
    
            @include from($maxContentWidth + (2 * outerMargin(xl))) {
                left: calc((100vw - #{$maxContentWidth}) / -2);
            }
        }
    }   
    // &__image {
    //     width: calc(((100vw - 240px + 21px)*(4/12)) + 120px - 21px);
    //     float: left;
    //     position: relative;
    //     // display: inline;
    //     left: -120px;
    // }

    // &__text {
    //     h2, p {
    //         max-width: calc(((100vw - 240px + 21px)*(7/12)));
    //     }
    // }
}
