.documents {
    a {
        transition: color 0.25s ease-out;
        span:first-child {
            transition: transform 0.25s ease-out;
        }
        &:hover {
            color: $colorWedgewood;
            span:first-child {
                transform: translateX(15px);
            }
        }
    }
}
