/*

    @font-face declarations

    Paths to url() should be relative to the SASS entrypoint (base.scss), i.e. "../fonts/fontname.woff2"

 */

/*@font-face {
    font-family: 'MyWebFont';
    font-weight: normal;
    font-display: fallback;
    font-style: italic;
    src: url('../fonts/webfont.woff2') format('woff2'), !* Super Modern Browsers *!
    url('../fonts/webfont.woff') format('woff'), !* Pretty Modern Browsers *!
    url('../fonts/webfont.ttf') format('truetype') !* Safari, Android, iOS *!
;
}*/


@font-face {
    font-family: 'Untitled';
    font-weight: 200;
    font-display: fallback;
    font-style: normal;
    src: url('../fonts/UntitledSansWeb-Light.woff2') format('woff2');
}

@font-face {
    font-family: 'Untitled';
    font-weight: 200;
    font-display: fallback;
    font-style: italic;
    src: url('../fonts/UntitledSansWeb-LightItalic.woff2') format('woff2');
}

@font-face {
    font-family: 'Untitled';
    font-weight: 400;
    font-display: fallback;
    font-style: normal;
    src: url('../fonts/UntitledSansWeb-Regular.woff2') format('woff2');
}

@font-face {
    font-family: 'Untitled';
    font-weight: 400;
    font-display: fallback;
    font-style: italic;
    src: url('../fonts/UntitledSansWeb-RegularItalic.woff2') format('woff2');
}