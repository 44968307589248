.login {
    &__input {
        appearance: none;
        border: 0;
        padding: 0.8em 0;
        border-bottom: 2px solid $colorCasper;
        line-height: 1;
    }

    &__button {
        border: 0;
        appearance: none;
        padding: 24px 54px;
        margin-top: 70px;
        
        @include from(m) {
            padding: 24px 68px;
        }
    }
}