.event-hero {
    width: 100vw;
    height: 100vh;
    @supports (--custom:property) {
        height: calc(var(--vh, 1vh) * 100);
    }
    margin-bottom: 54px;

    @include from(m) {
        margin-bottom: 56px;
    }
    @include from(l) {
        margin-bottom: 0;
    }
    &--after {
        margin-bottom: 0;
    }

    &__box {
        @include from(l) {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 50%;
            z-index: 100;
        }
        &-calendar {
            margin-bottom: -54px;
            @include from(m) {
                margin-bottom: -56px;
            }
            @include from(l) {
                margin-bottom: 0;
            }
        }
    }

    &__counter {
        display: inline-block;
        transform-origin: center center;
    }

}

.heartbeat {
    animation: heartbeat 1s ease-in-out infinite both;
}

@keyframes heartbeat {
    from {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
    10% {
        transform: scale(0.91);
        animation-timing-function: ease-in;
    }
    17% {
        transform: scale(0.98);
        animation-timing-function: ease-out;
    }
    33% {
        transform: scale(0.87);
        animation-timing-function: ease-in;
    }
    45% {
        transform: scale(1);
        animation-timing-function: ease-out;
    }
}
