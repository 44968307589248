.funds {
    &__fund-link {
        border-bottom: 2px solid transparent;

        @include from(m) {
            padding-bottom: 5px;
        }
        
        &.is-active {
            color: $colorWedgewood;
            border-color: $colorWedgewood;
        }
    }
}