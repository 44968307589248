.videos {
    &__wrapper {
        > div, > iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }
}
