.menu {

    &:not([data-component-booted]):target {
        display: block !important;
    }

    &__panel {
        
        min-height: 100vh;
        height: 100vh;
        width: 100%;

        @include scrollable;
       
        @include from(l) {
            width: 50%;
        }

        &-inner {

            padding-top: 44px;
            padding-left: outerMargin() - gutter()/2;
            padding-right: outerMargin() - gutter()/2;

            @include from(m) {
                padding-top: 70px;
                padding-left: outerMargin(m) - gutter(m)/2;
                padding-right: outerMargin(m) - gutter(m)/2;
            }
            @include from(l) {

                padding-left: 60px;
                padding-right: outerMargin(xl) - gutter(xl)/2;
            }
    
            @include from($maxContentWidth + (2 * outerMargin(xl))) {
                padding-right: 0;
            }
        }
    }

    &__items,
    &__search > * {
        width: 100%;

        @include from($maxContentWidth + (2 * outerMargin(xl))) {
            width: $maxContentWidth/2 + gutter(xl)/2;
            max-width: 100%;
        }
    }

    &__item {
        @include between(m,l) {
            padding-left: 27px;
        }

        &.is-active {
            &:before {
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                background: currentColor;
                border-radius: 50%;
                top: 19px;
                //margin-top: -4px;
                left: -18px;
                
                @include until(m) {
                    display: none;
                }

                @include between(m,l) {
                    left: 0;
                }
            }
        }
    }
}
