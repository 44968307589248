.cases {
    &__image {
        padding-bottom: 75%;

        @include from(m) {
            padding-bottom: 0;
            @media (orientation: landscape) {
                min-height: 80vh;
            }
            @media (orientation: portrait) {
                min-height: 62vw;
            }
        }
    }

    &__text {
        //max-width: 80%;

        @include from(m) {
            max-width: 82%;
        }

        @include from(l) {
            max-width: 75%;
        }

        @include from(xl) {
            max-width: 650px;
        }
    }

    &__sub-image {
        height: 100%;
        width: 50%;
    }

    &__text-wrap {
        display: grid;
        grid-auto-rows: 1fr;
    }
}
