.contact {
    &__image {
        @include until(m) {
            padding-bottom: 68%;
        }
    }

    &__image-wrap {
        @include from(m) {
            min-height: 50vw;
        }
    }
}