.front {
    &__numbers {
        & + & {
            padding-top: 0;
        }

        //min-height: 80vh;
    }

    &__image-box {
        height: 100vw;

        @include from(m) {
            height: auto;
        }
    }

    &__text-box {
        padding: 15px 38px 30px 38px;

        @include from(m) {
            padding: 17px;
        }

        @include from(l) {
            padding: 26px;
        }
    }

    &__animation {
        height: 100vw;
        overflow: hidden;
        @include from(m) {
            height: auto;
        }
        &-image {
            &-wrapper {
                opacity: 0;
            }
            &:nth-child(4n+1) {
                bottom: -1px;
                right: -1px;
                div {
                    //transform-origin: bottom right;
                }
            }
            &:nth-child(4n+2) {
                top: -1px;
                left: -1px;
                div {
                    //transform-origin: top left;
                }
            }
            &:nth-child(4n+3) {
                bottom: -1px;
                left: -1px;
                div {
                    //transform-origin: bottom left;
                }
            }
            &:nth-child(4n+4) {
                top: -1px;
                right: -1px;
                div {
                    //transform-origin: top right;
                }
            }
        }
    }

    &__feature-link {

        &:nth-of-type(odd) {
            .front__feature-link-text-wrap {
                &:not([style]) {
                    background: $colorWedgewood;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    color: $colorWhite;
                }

                @include from(m) {
                    order: 1;
                }
            }

            .front__feature-link-sub-image {
                @include from(m) {
                    margin-left: auto;
                }
            }
        }

        &:nth-of-type(even) {
            .front__feature-link-text-wrap {
                background: $colorSelago;

                @include from(m) {
                    order: -1;
                }
            }

            .front__feature-link-sub-image {
                margin-left: auto;

                @include from(m) {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }
    }

    &__text-image-fit {
        @include from(m) {
            padding-bottom: 0 !important;
            @media (orientation: landscape) {
                min-height: 80vh;
            }
            @media (orientation: portrait) {
                min-height: 71vw;
            }
        }
    }
}
