.team {
    &__image {
        padding-bottom: 128%;
        &-placeholder {
            bottom: 0;
            right: 0;
        }
        &-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 24.125%;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }
}
