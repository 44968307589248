.investment {
    &__logo {
        @include from(m) {
            max-width: 200px;
        }

        @include from(m) {
            max-width: 250px;
        }
    }

    &__back {
        @include from(l) {
            .has-transparent-bar & {
                position: absolute;
                top: 0;
                width: 100%;
                padding-top: 70px;
                max-width: none;
                a {
                    color: #fff;
                }
            }
        }
    }
}
