.factboxes {
    &__box {
        padding: 20px 16px;
        min-height: 300px;

        @include from(m) {
            padding: 30px;
            min-height: 315px;
        }

        @include from(l) {
            padding: 45px;
            min-height: 475px;
        }

        @include until(m) {
            &:nth-of-type(even) {
                background: $colorWhite;
                color: $colorText;
            }
    
            &:nth-of-type(odd) {
                background: $colorWedgewood;
            }
        }

        @include from(m) {
            &:nth-of-type(4n - 3), &:nth-of-type(4n) {
                background: $colorWedgewood;
            }
    
            &:nth-of-type(4n - 2), &:nth-of-type(4n - 1) {
                background: $colorWhite;
                color: $colorText;
            }
        }

        .u-arrow--up,
        .u-arrow--down {
            margin-top: 0.1em;
        }

    }

    &__text {
        max-width: 300px;

        @include from(l) {
            max-width: 500px;
        }
    }
}
