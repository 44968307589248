.investments {
    &__item {
        min-height: 380px;
        margin-bottom: gutter(l);

        &-logo {
            display: flex;
            height: 42px;
            align-items: center;
            img {
                display: block;
                width: auto;
                max-width: 150px;
                max-height: 42px;
                mix-blend-mode: multiply;
            }
        }

        a {
            transition: background-color 0.3s;
        }

        @include from(l) {
            min-height: 400px;
        }

        @include between(m,l) {
            padding-left: gutter(l)/2;
            padding-right: gutter(l)/2;
        }
    }

    &__items {
        @include between(m,l) {
            padding-left: outerMargin(m) - gutter(l)/2;
            padding-right: outerMargin(m) - gutter(l)/2;
        }
    }

    &--exited {
        [data-exited="0"] {
            display: none;
        }
    }

    &--current {
        [data-exited="1"] {
            display: none;
        }
    }

    &__toggle {

        border-bottom: 2px solid transparent;

        @include from(m) {
            padding-bottom: 5px;
        }
        &--exited {
            .investments--exited & {
                color: $colorWedgewood;
                border-color: $colorWedgewood;
            }
        }

        &--current {
            .investments--current & {
                color: $colorWedgewood;
                border-color: $colorWedgewood;
            }
        }
    }
}
