.footer {
    padding-top: 34px;
    padding-bottom: 42px;

    @include from(m) {
        padding-top: 50px;
        padding-bottom: 60px;
    }

    @include from(l) {
        padding-top: 70px;
        padding-bottom: 50px;
    }

    &__logo {
        position: relative;
        margin-left: -10px;

        width: 144px;
        height: 33px;

        @include from(m) {
            margin-left: -14px;
            width: 197px;
            height: 45px;
        }

        svg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        path {
            fill: currentColor;
        }
    }

    &__menu-item {
        @include until(m) {
            width: 33.33333%;
            flex: 0 0 auto;
        }

        @include between(m,800) {
            width: percentage(1/4);
            flex: 0 0 auto;
        }

        @include between(800,l) {
            width: percentage(1/5);
            flex: 0 0 auto;
        }
    }
}
