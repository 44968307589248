.hero {

    height: 65vh;

    @include until(m) {
        @media (max-aspect-ratio: 520/730) {
            height: 0;
            padding-bottom: percentage(460/375);
        }
    }

    @include between(m, l) {
        @media (max-aspect-ratio: 780/1080) {
            height: 0;
            padding-bottom: percentage(410/769);
        }
    }

    @include from(l) {
        height: 72vh;
    }

    .investment & {
        @include from(l) {
            margin-bottom: 100px;
        }
    }

    .front &, .page--generic.has-hero-text & {
        height: 100vh;
        padding-bottom: 0 !important;
    }
}
