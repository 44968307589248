.linkboxes {

    @include until(m) {
        max-width: none;
        padding-left: 0;
        padding-right: 0;
    }

    @include from(l) {
        max-width: maxWidth(xl) + (outerMargin(xl) * 3);
    }

    &__box {

        $box: &;

        @include until(m) {
            &:nth-child(odd) {
                background-color: $colorSilverRust;
            }
        }

        @include from(m) {

            min-height: 45vw;

            &::before {
                content: "";
                display: block;
                width: 50vw;
                height: 100%;
                position: absolute;
                background-color: inherit;
                top: 0;
                left: 100%;
            }

            &:nth-child(odd)::before {
                left: auto;
                right: 100%;
            }

            &:nth-child(4n+1), &:nth-child(4n) {
                background-color: $colorSilverRust;
            }
        }

        @include from(mp) {
            min-height: 35vw;
        }

        @include from($maxContentWidth) {
            min-height: 400px;
        }

        &-inner {
            @include from(m) {
                padding: 0 0 0 30px;
                #{$box}:nth-child(odd) & {
                    padding: 0 30px 0 0;
                }
            }

            @include from(l) {
                padding: 0 0 0 50px;
                #{$box}:nth-child(odd) & {
                    padding: 0 50px 0 0;
                }
            }
        }

        &-text > * {
            max-width: 42ch;
        }
    }
}
