.header {
    &[data-component-booted] {
        height: 84px + 27px;

        @include from(m) {
            height: 84px + 47px;
        }
        
        .has-transparent-bar & {
            height: 0;
        }

        .has-back-link & {
            @include from(l) {
                height: 80px;
            }
        }
    }
    &__bar {
        height: 84px + 27px;
        transition: color 0.5s;
        color: transparentize(#fff, 0);

        @include from(m) {
            height: 84px + 47px;
        }
        .has-transparent-bar & {
            color: transparentize(#fff, 1);
        }

        .has-transparent-bar .is-opaque & {
            color: transparentize(#fff, 0);
        }

        // @include until(l) {
            .has-transparent-bar .is-opaque.has-open-menu & {  
                color: transparentize(#fff, 1);
            }
            
            .has-open-menu & {  
                color: transparentize(#fff, 1);
            }
        // }

        
        &-bg {
            background: currentColor;
            height: 101px;
            position: relative;

            width: (outerMargin() * 2) + 30px;
            right: -(outerMargin());

            @include from(m) {
                height: 84px + 47px;
                width: (outerMargin(m) * 2) + 34px;
                right: -(outerMargin(m));
            }
        }
    }
    &__content-wrap {
        // background: red;
        padding: 39px 0 20px;
        transition: color 0.5s;
        

        @include from(m) {
            padding: (15px + 47px) 0 25px;
        }

        .has-transparent-bar & {
            color: $colorWhite;
        }

        
    }

    &__toggle {

        position: relative;

        margin-top: 15px;
        transition: color 0.5s;

        @include from(m) {
            margin-top: 20px;
        }

        .has-transparent-bar .is-opaque & {
            color: $colorText;
        }

        .has-transparent-bar .is-opaque.has-open-menu & {
            color: $colorWhite;
        }

        .has-open-menu & {
            color: $colorWhite;
        }
    }

    &__logo {
        width: 144px;
        height: 33px;
        position: relative;
        left: -10px;
        display: block; 

        @include from(m) {
            left: -14px;
            width: 197px;
            height: 45px;
        }

        svg {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }

        path {
            fill: currentColor;
        }

        @include until(l) {
            transition: transform 0.65s, opacity 0.35s;
            .has-open-menu & {
                pointer-events: none;
                opacity: 0;
                transform: translateY(-47px);
            }
        }
    }

    &__hamburger {
        width: 30px;

        position: relative;
        &::before {
            content: "";
            display: block;
            width: 124px;
            height: 101px;
            position: absolute;
            left: 50%; top: 50%;
            margin: -62px 0 0 -62px;
            opacity: 0.5;
        }

        @include from(m) {
            width: 34px;
        }
        
        span {
            display: block;
            width: 100%;
            height: 3px;
            margin-bottom: 5px;
            backface-visibility: hidden;
            background-color: currentColor;
            transition: transform 0.5s;

            .has-open-menu & {
                transition: transform 0.5s;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &:nth-of-type(1) {
                .has-open-menu & {
                    transform: translateY(8px) rotate(45deg);
                }
            }

            &:nth-of-type(3) {
                .has-open-menu & {
                    transform: translateY(-8px) rotate(-45deg);
                }
            }

            &:nth-of-type(2) {
                .has-open-menu & {
                    transform: scaleX(0);
                }
            }
        }
    }
}
