.search {
    
    &__items {
        @include between(m,l) {
            padding-left: outerMargin(m) - gutter(l)/2;
            padding-right: outerMargin(m) - gutter(l)/2;
        }
    }

    &__icon {
        width: 23px;
        height: 23px;
        color: currentColor;

        @include from(m) {
            width: 26px;
            height: 26px;
        }

        @include from(l) {
            width: 32px;
            height: 32px;
        }

        .menu & {
            width: 27px;
            height: 27px;
            
        }

        position: relative;

        svg {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }

        path {
            fill: currentColor;
        }
    }

    &__button {
        
        .menu & {
            top: auto;
            bottom: 0;
            height: auto;
            opacity: 0;

            
        }

        input:focus + &, .has-input + & {
            opacity: 1;
        }
    }

    &__input {
        appearance: none;
        border: 0;
        padding: 0.8em 50px 0.8em 0;
        border-bottom: 2px solid currentColor;
        line-height: 1;
        color: currentColor;

        .menu & {
            font-size: 24px;
            font-weight: 200;

            @include from(m) {
                font-size: 32px;
            }
            border: 0;
            padding-bottom: 0;
        }

        .search & {
            padding: 0.5em 50px 0.5em 0;
        }
    }

    &__item {
        $item: &;

        margin-bottom: 44px;

        @include between(m,l) {
            margin-bottom: 34px;
            padding-left: gutter(l)/2;
            padding-right: gutter(l)/2;
        }

        @include from(l) {
            margin-bottom: 40px;
        }

        &-box {
            #{$item}:nth-of-type(3n-2) & {
                top: 0;
                left: 0;
            }

            #{$item}:nth-of-type(3n-1) & {
                bottom: 0;
                left: 0;
            }

            #{$item}:nth-of-type(3n) & {
                bottom: 0;
                right: 0;
            }

            // #{$item}:nth-of-type(4n) & {
            //     top: 0;
            //     right: 0;
            // }
        }
    }

    &__filter {
        margin-bottom: 26px;

        @include from(m) {
            margin-bottom: 16px;
        }

        @include from(l) {
            margin-bottom: 26px;
        }

        a {
            display: block;
            border-bottom: 2px solid transparent;

            &.is-active {
                border-color: currentColor;
            }
        }

        li {
            margin-right: 38px;

            margin-bottom: 20px;
        }
        
    }
}