.news {
    &__year {
        &.is-active {
            color: $colorShark;
        }

        &-dot {
            width: 5px;
            height: 5px;
            background: transparent;
            margin-top: 8px;
            border-radius: 50%;

            @include from(m) {
                margin-top: 12px;
            }

            .is-active & {
                background: currentColor;
            }
        }
    }

    .flickity-viewport {
        overflow: visible;
    }

    &__link {
        &:hover {
            .news__link-title {
                text-decoration: underline;
            }
        }
    }
}