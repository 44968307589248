.wysiwyg {
    p, h2, ol, ul, li {
        &:last-child {
            margin-bottom: 0;
        }
    }

    p, h2 {
        & + ol, & + ul {
            .person & {
                margin-top: -15px;
            }
        }
    }

    ul {
        list-style-type: disc;
        padding-left: 1em;
    }

    ol {
        list-style-type: decimal;
        padding-left: 1em;
    }

    li {
        display: list-item;
    }
}
